import React, { useState, useContext } from 'react'
import { Link } from 'gatsby'
import tw from 'twin.macro'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import { getLastTextIndexes } from '../utils'
import { use100vh } from 'react-div-100vh'

import { Layout, ImageOrVideo } from '../components'
import { media } from '../styles/utils'
import { container, padding, bgImage, bgIcon, maxWidth, button, textDot } from '../styles/global'
import { headerHeight } from '../components/Header/Header'

import { PageTitleContext } from '../context/PageTitleContext'
import { useMount } from 'react-use'
import forEach from 'lodash.foreach'

const NewsSingle = props => {
    const data = props?.pageContext?.data
    const { pageTitle, setPageTitle } = useContext(PageTitleContext)
    // console.log(data)

    useMount(() => {
        setPageTitle('News')
    })

    const renderCats = () => {
        if (!data?.cats_all) return

        const items = data?.cats_all?.map((item, i) => {
            let active = false

            forEach(data?.cats, (cat, i) => {
                if (cat?.slug == item?.slug) {
                    active = true
                }
            })


            return (
                <Item
                    key={i}
                    as={Link}
                    to={`/news/?filter=${item?.slug}`}
                    className={active && 'active'}
                >
                    {item?.title}
                </Item>
            )
        })

        return (
            <Container>
                <Cats>
                    <Item
                        as={Link}
                        to={`/news/?filter=all`}
                    >
                        {'All'}
                    </Item>
                    {items}
                </Cats>
            </Container>
        )
    }

    const renderMedia = () => {
        if (!data?.news_media) return

        const items = data?.news_media?.map((item, i) => {
            return (
                <ImageOrVideo
                    media_type={item?.media_type}
                    image={item?.image}
                    video={item?.video}
                    video_embed={item?.video_embed}
                />
            )
        })

        return (
            <Items>
                {items}
            </Items>
        )
    }

    return (
        <Layout meta={data && data.seo}>
            <Wrapper>
                {renderCats()}
                <Container>
                    <Grid>
                        <Left>
                            {renderMedia()}
                        </Left>
                        <Right>
                            <Heading>{data?.title}</Heading>
                            <Description
                                className={'tiny-mce'}
                                dangerouslySetInnerHTML={{
                                    __html: data?.news_content,
                                }}
                            />
                        </Right>
                    </Grid>
                </Container>
            </Wrapper>
        </Layout>
    )
}

// Shared

const Heading = styled.h1``
const Subheading = styled.h2``
const Description = styled.div``
const Info = styled.div``
const Item = styled.div``

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const BGImage = styled.div`
    background-image: url(${props => props.image});  
    ${bgImage};
    transition: opacity 0.45s ease;
`


// Layout

const Wrapper = styled.div`
    ${tw`w-full`};
    padding-top: ${headerHeight};
`

const Container = styled.div`
    ${container};
    ${padding};
`

// Cats

const Cats = styled.div`
    display: flex;
    flex-flow: row wrap;
    margin-top: 2rem;
    padding: 1.5rem 0;
    ${tw`border-t border-b border-darkGrey`};

    ${Item} {
        ${textDot}
        text-transform: capitalize;
        margin-right: 1rem;
        ${tw`text-darkGrey`};

        &.active {
            ${tw`text-black`};
            ${textDot('#000000')}
        }
    }
`

// Grid System

const Grid = styled.div`
    ${tw`flex`};
    margin-top: 3rem;
    margin-bottom: 3rem;

    ${media.phone`
        ${tw`flex-col`};
    `}

    > *:first-child,
    > *:last-child {
        ${tw`w-1/2`};

        ${media.phone`
            ${tw`w-full`};
        `}
    }

    > *:first-child {
        padding-right: 1.5rem;

        ${media.phone`
            padding-right: 0;
            margin-bottom: 3rem;
        `}
    }

    > *:last-child {
        padding-left: 1.5rem;

        ${media.phone`
            padding-left: 0;
        `}
    }
`

// Left (media)

const Items = styled.div``
const Left = styled.div`
    ${Items} > *:not(:last-child) {
        margin-bottom: 3rem;
    }
`


// Right (content)

const Right = styled.div`
    ${Heading} {
        padding: 1rem 0;
        ${tw`border-t border-b border-darkGrey`};
        line-height: 1;
    }

    ${Description} {
        margin-top: 2.5rem;
    }
`


export default NewsSingle
